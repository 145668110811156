import {
  ref,
  onUnmounted,
  onMounted,
  watch,
  type ComponentPublicInstance,
} from "vue";
import {
  createSharedComposable,
  unrefElement,
  type MaybeElementRef,
} from "@vueuse/core";

type SectionItem = {
  id: string;
  label: string;
  order?: number;
  el?: Element | ComponentPublicInstance | null;
};

function useSectionNav() {
  const sectionRefs = ref<MaybeElementRef[]>([]);
  const sectionItems = ref<SectionItem[]>([]);
  const sectionVisible = ref<string | null>(null);
  const isScolling = ref(false);
  let onSectionChangeCallback: Function | null = null;
  let observer: IntersectionObserver | null = null;

  const observeSections = () => {
    sectionRefs.value?.forEach((el) => {
      if (el instanceof Element) {
        observer?.observe(el);
      }
    });
  };

  const unobserveSections = () => {
    sectionRefs.value?.forEach((el) => {
      if (el instanceof Element) {
        observer?.unobserve(el);
      }
    });
  };

  watch(
    sectionRefs,
    (newRefs) => {
      if (!newRefs) return;
      unobserveSections();
      observeSections();
    },
    { immediate: true, flush: "post", deep: true }
  );

  onMounted(() => {
    observer = new IntersectionObserver(
      (entries) => {
        if (isScolling.value) return;

        let maxRatio = 0;
        let mostVisibleSectionId = "";

        entries.forEach((entry) => {
          const { isIntersecting, target, intersectionRatio } = entry;
          if (isIntersecting && intersectionRatio > maxRatio) {
            maxRatio = intersectionRatio;
            mostVisibleSectionId = target.id;
          }
        });

        if (mostVisibleSectionId) {
          const newSectionId = mostVisibleSectionId;
          const oldSectionId = sectionVisible.value;
          sectionVisible.value = newSectionId;

          // Trigger the onSectionChange event
          if (newSectionId !== oldSectionId) {
            onSectionChangeCallback?.(newSectionId);
          }
        }
      },
      {
        root: document,
        // Root margin => top right bottom left
        rootMargin: `-34.5% 0px -64.5% 0px`,
        threshold: [0, 0.25, 0.5, 0.75, 1],
      }
    );

    observeSections();
  });

  onUnmounted(() => {
    unobserveSections();
    observer = null;
  });

  function addToSectionNav({ el, id, label, order }: SectionItem) {
    // check if the sectionItems already exists
    const exists = sectionItems.value.find((item) => {
      return item.id === id;
    });
    if (exists) return;
    sectionRefs.value.push(unrefElement(el as MaybeElementRef));
    sectionItems.value.push({ id, label, order });
    // set the sectionVisible to the first section
    if (!sectionVisible.value) {
      sectionVisible.value = id;
    }
  }

  function scrollToSection(sectionId: string, scrollMarginTop = 0) {
    unobserveSections();
    isScolling.value = true;
    const sectionEl = document.getElementById(sectionId);
    if (sectionEl) {
      sectionVisible.value = sectionId;
      window.scrollTo({
        top: sectionEl.offsetTop - scrollMarginTop,
        behavior: "smooth",
      });
      setTimeout(() => {
        onSectionChangeCallback?.(sectionId);
        observeSections();
        setTimeout(() => {
          isScolling.value = false;
        }, 500);
      }, 500);
    }
  }

  function scrollToSectionNav(sectionId: string) {
    const child = document.getElementById(`${sectionId}-nav`);
    if (!child || !child.parentElement) return;
    const parent = child.parentElement.closest(".scroller");
    if (!(parent instanceof HTMLElement)) return;

    const parentWidth = parent.offsetWidth;
    const childWidth = child.offsetWidth;
    const childOffsetLeft = child.offsetLeft - parent.offsetLeft;
    const centerOffset = (parentWidth - childWidth) / 2;
    const scrollAmount = childOffsetLeft - centerOffset;

    parent.scrollTo({
      left: scrollAmount,
      behavior: "smooth",
    });
  }

  function onSectionChange(callback: (id: string) => void) {
    onSectionChangeCallback = callback;
  }

  return {
    sectionItems,
    sectionVisible,
    addToSectionNav,
    onSectionChange,
    scrollToSection,
    scrollToSectionNav,
  };
}

export default createSharedComposable(useSectionNav);
